import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from '@angular/core';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { filter } from 'rxjs/operators';
import {
  AnyAccessType,
  Consumer,
  isManagableOrderOrConsumer,
} from 'src/app/shared/models/consumers';
import { ManagableOrder } from 'src/app/shared/models/orders';
import { ConsumerDataItemComponent } from '../consumer-data-item/consumer-data-item.component';
import { DatePipe, KeyValuePipe } from '@angular/common';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { UnauthConsumer } from 'src/app/shared/models/user';

@Component({
  selector: 'win-consumer-data',
  templateUrl: './consumer-data.component.html',
  styleUrls: ['./consumer-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ConsumerDataItemComponent, DatePipe, KeyValuePipe, TranslocoPipe],
})
export class ConsumerDataComponent implements OnChanges {
  private translate = inject(TranslocoService);

  @Input() set consumer(
    value: AnyAccessType | ManagableOrder | UnauthConsumer,
  ) {
    this._consumer = value;

    if (value?.diets_detail?.length)
      this.dietsNames = value.diets_detail
        .map((diet) => {
          if (typeof diet === 'string') {
            return diet;
          }
          if (diet.info_url) {
            return `<a href="${diet.info_url}" target="_blank">${diet.diet_name}</a>`;
          }
          return diet.diet_name;
        })
        .join(' | ');
    if (isManagableOrderOrConsumer(value) && value?.allergies?.length)
      this.allergies = value.allergies
        .map((allergy) => {
          return this.allergens[allergy];
        })
        .join(' | ');
    if (isManagableOrderOrConsumer(value) && value?.intolerances?.length)
      this.intolerances = value.intolerances
        .map((intolerance) => {
          return this.allergens[intolerance];
        })
        .join(' | ');
  }
  get consumer(): AnyAccessType | ManagableOrder | UnauthConsumer {
    return this._consumer;
  }
  @Input() set dietsScheduled(value: string[]) {
    this._dietsScheduled = value;
    if (value?.length) {
      this.dietsScheduledNames = value.join(', ');
    }
  }
  get dietsScheduled(): string[] {
    return this._dietsScheduled;
  }
  @Input() lang: InterfaceLanguage;

  _consumer: AnyAccessType | ManagableOrder | UnauthConsumer;
  _dietsScheduled: string[];

  dietsNames: string;
  dietsScheduledNames: string;
  allergies: string;
  intolerances: string;
  allergens: Record<string, string> = {};
  consumerObject: Consumer | ManagableOrder;

  constructor() {
    this.translate
      .selectTranslateObject('shared.content.allergens')
      .pipe(filter((t) => !!t))
      .subscribe(
        (allergens: Record<string, string>) => (this.allergens = allergens),
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('consumer' in changes && this.consumer) {
      this.consumerObject = isManagableOrderOrConsumer(this.consumer)
        ? this.consumer
        : null;
    }
  }
}
