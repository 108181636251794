import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  AnyAccessType,
  isManagableOrderOrConsumer,
} from 'src/app/shared/models/consumers';
import {
  CONSISTENCY_LEVELS,
  TEXTURE_LEVELS,
} from 'src/app/shared/models/diets';
import {
  Consistency,
  ManagableOrder,
  Order,
  OrderVariant,
  Texture,
} from 'src/app/shared/models/orders';

import { ProductSheetDialogComponent } from '../product-sheet-dialog/product-sheet-dialog.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ErrorStateMatcher, MatOptionModule } from '@angular/material/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

export class ShowImmediatelyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form?.submitted;
    return !!(
      control?.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'win-order-description',
  templateUrl: './order-description.component.html',
  styleUrls: ['./order-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatOptionModule,
    TextFieldModule,
    TranslocoPipe,
  ],
})
export class OrderDescriptionComponent implements OnChanges, OnInit {
  private destroyRef = inject(DestroyRef);
  private dialog = inject(MatDialog);

  @Input() autocomplete: string[] | null = null;
  @Input() consumer: AnyAccessType | ManagableOrder;
  @Input() featureConsistency: boolean;
  @Input() featureTexture: boolean;
  @Input() isAgent: boolean;
  @Input() isManager: boolean;
  @Input() order: Order;
  @Input() isOffline: boolean;
  @Input() minQuantity: number;
  @Input() quantityFieldIncrement: number;
  @Input() quantityFieldInput: boolean;
  @Input() showDescription: boolean;
  @Input() showOnlyPortionSize: boolean;
  @Input() showOnlyQuantity: boolean;

  @Output() descriptionChange = new EventEmitter<
    Partial<{
      description: string;
      quantity: number;
      portion_size: number;
      consistency: Consistency;
      texture: Texture;
      variants: OrderVariant[];
    }>
  >();
  @Output() fetchProductSheet = new EventEmitter<{ token: string }>();
  @Output() searchAutocomplete = new EventEmitter<{ value: string }>();

  consistencyLevels = CONSISTENCY_LEVELS;
  portionSizes = [0.5, 1, 1.5, 2];
  textureLevels = TEXTURE_LEVELS;
  currentQuantity = 1;
  matcher = new ShowImmediatelyErrorStateMatcher();

  public descForm = new FormGroup({
    description: new FormControl<string>(''),
    quantity: new FormControl<number>(1, { validators: [Validators.min(1)] }),
    portion_size: new FormControl<number>(1),
    consistency: new FormControl<null | Consistency>(null),
    texture: new FormControl<null | Texture>(null),
    variants: new FormControl<OrderVariant[]>([]),
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (
      'quantityFieldIncrement' in changes &&
      this.quantityFieldIncrement &&
      this.quantityFieldIncrement !== this.descForm.controls.quantity.value
    ) {
      this.descForm.controls.quantity.setValue(this.quantityFieldIncrement, {
        emitEvent: false,
      });
      this.descForm.controls.quantity.setValidators([
        Validators.min(this.quantityFieldIncrement),
        this.invalidQuantityValidator(),
      ]);
      this.descForm.controls.quantity.updateValueAndValidity();
    }
    if ('order' in changes && this.order) {
      this.descForm.patchValue(
        {
          description: this.order?.description,
          quantity: this.order?.quantity
            ? this.order?.quantity
            : this.quantityFieldIncrement,
          portion_size:
            this.order?.portion_size ?? this.consumer?.portion_size ?? 1,
          consistency:
            this.order?.consistency ??
            (isManagableOrderOrConsumer(this.consumer)
              ? this.consumer.consistency
              : null),
          texture:
            this.order?.texture ??
            (isManagableOrderOrConsumer(this.consumer)
              ? this.consumer.texture
              : null),
          variants: this.order?.variants || [],
        },
        { emitEvent: false },
      );
    }
  }

  openProductSheetModal() {
    this.fetchProductSheet.emit({ token: this.order.productSheet });
    this.dialog.open(ProductSheetDialogComponent, {
      width: '800px',
      maxWidth: '100vw',
      maxHeight: '95vh',
    });
  }

  updateQuantity(action: 'increase' | 'decrease') {
    let newQuantity =
      this.descForm.controls.quantity.value! +
      (action === 'increase'
        ? this.quantityFieldIncrement
        : -1 * this.quantityFieldIncrement);
    newQuantity =
      newQuantity < this.minQuantity ? this.minQuantity : newQuantity;
    this.descForm.controls.quantity.setValue(newQuantity);
  }

  ngOnInit() {
    this.descForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((v) => {
        if (this.descForm.valid) this.descriptionChange.emit(v);
      });
  }

  clearConsistency(event: MouseEvent) {
    event.stopPropagation();
    this.descForm.patchValue({ consistency: null });
  }

  clearTexture(event: MouseEvent) {
    event.stopPropagation();
    this.descForm.patchValue({ texture: null });
  }

  invalidQuantityValidator = (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value % this.quantityFieldIncrement !== 0
        ? { invalidQuantity: { value: control.value } }
        : null;
    };
  };

  searchAuto(value: string) {
    this.autocomplete = null;
    this.searchAutocomplete.emit({ value });
  }

  selectAuto(value: string) {
    if (value) this.descForm.patchValue({ description: value });
  }
}
