import { DietDetailShort } from './diets';
import { ManagableItem, UnknownManagableItem } from './misc';
import {
  Consistency,
  ManagableOrder,
  ManagableOrderConsumer,
  ManagableOrderUser,
  Texture,
} from './orders';
import { CustomData, PrivacyLevel, UnauthConsumer, User } from './user';

export interface ManagableAccess {
  terminal_protected: boolean;
  terminal_privacy: PrivacyLevel;
  birthday: string;
  birthday_image: string;
  checkin: string;
  checkin_time: string;
  checkout: string;
  checkout_time: string;
  created_at: string;
  custom_data: CustomData;
  diets: string[];
  email: string;
  errors?: Record<string, string>;
  external_id: string;
  vip: boolean;
  id: number;
  is_user: boolean | null;
  is_kds: boolean;
  is_service: boolean;
  is_terminal: boolean;
  terminal_type: number;
  has_date_order?: boolean;
  has_service_order?: boolean;
  location: number | null;
  name: string;
  resent?: boolean;
  rfid: string;
  role: number;
  room: string;
  room_floor: string;
  department: string;
  table_number: string;
  type: number | null;
  typology: string;
  url: string;
  last_modified_at: string;
  diets_detail?: DietDetailShort[];
  type_name?: string;
  consistency?: Consistency;
  texture?: Texture;
  portion_size?: number;
  allergies?: string[];
  intolerances?: string[];
}

export interface Consumer {
  id: number;
  url: string;
  created_at: string;
  last_modified_at: string;
  name: string;
  rfid: string;
  table_number: string;
  room_floor: string;
  department: string;
  checkin: string;
  checkin_time: string;
  checkout: string;
  checkout_time: string;
  room: string;
  typology: string;
  custom_data: CustomData;
  created_by: number;
  last_modified_by: number;
  location: number;
  organisation: number;
  location_name?: string;
  diets: string[];
  diets_detail: DietDetailShort[];
  type?: number;
  type_name?: string;
  vip: boolean;
  portion_size?: number;
  allergies?: string[];
  intolerances?: string[];
  consistency?: Consistency;
  texture?: Texture;
  birthday?: string;
  uuid?: string;
}

export type AnyAccessType = ManagableAccess | Consumer | User;

export function isManagableAccess(
  item: ManagableItem | ManagableOrderConsumer | ManagableOrderUser,
): item is ManagableAccess {
  return (item as ManagableAccess)?.external_id !== undefined;
}

export function isManagableItem(
  item: ManagableItem | UnknownManagableItem,
): item is ManagableItem {
  return item && 'is_user' in item;
}

export function isManagableOrderOrConsumer(
  consumer: AnyAccessType | ManagableOrder | UnauthConsumer,
): consumer is ManagableOrder | Consumer {
  return (consumer as ManagableOrder)?.consistency !== undefined;
}

export function isConsumer(
  consumer: ManagableItem | Consumer | UnauthConsumer | User,
): consumer is Consumer {
  return (consumer as Consumer)?.birthday !== undefined;
}
