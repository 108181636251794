import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { EMPTY } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  retry,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { API_CONFIG } from 'src/app/app.config';
import { User } from 'src/app/shared/models/user';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { downloadFile } from 'src/app/shared/utils.functions';

import { UserChangedData } from '../../shared/models/user';
import { selectGlobalLanguage } from '../global/global.selectors';
import { UserService } from '../../shared/services/user/user.service';
import {
  logout,
  logoutCompletely,
} from './../authentication/authentication.actions';
import {
  handleHttpError,
  setGlobalError,
  setGlobalLanguage,
  showSnackbarMessage,
} from './../global/global.actions';
import {
  changePassword,
  connectWebSocket,
  deleteAccount,
  disconnectWebSocket,
  downloadReport,
  fetchAvailableReports,
  fetchConsumerOrganisation,
  getUser,
  getUserAndRedirect,
  patchUser,
  removeReportDownloading,
  removeUserLocally,
  reportWebSocketError,
  restoreUserLocally,
  saveUserLocally,
  setAvailableReports,
  setReportDownloading,
  setUnauthConsumer,
  setUser,
  viewReport,
} from './user.actions';
import { selectUser } from './user.selectors';
import { selectIsSimpleAuth } from '../authentication/authentication.selectors';
import { WebsocketService } from 'src/app/shared/services/websocket/websocket.service';

@Injectable()
export class UserEffects {
  private actions$ = inject(Actions);
  private router = inject(Router);
  private store = inject(Store);
  private transloco = inject(TranslocoService);
  private userService = inject(UserService);
  private utilsService = inject(UtilsService);
  private websockets = inject(WebsocketService);

  removeUserLocally$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeUserLocally),
        mergeMap(() => {
          localStorage.removeItem(`user`);
          return EMPTY;
        }),
        catchError(() => EMPTY),
      ),
    { dispatch: false },
  );

  fetchConsumerOrganisation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchConsumerOrganisation),
      switchMap(() =>
        this.userService.getConsumerOrganisation().pipe(
          map((simpleConsumer) => setUnauthConsumer({ simpleConsumer })),
          catchError((error: unknown) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  fetchUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUser),
      switchMap(() =>
        this.userService.getUser().pipe(
          tap((user) => {
            const userLocation = user?.location_detail;
            if (
              userLocation?.real_time_orders === true ||
              (!userLocation && user?.organisation?.real_time_orders === true)
            ) {
              this.store.dispatch(connectWebSocket());
            }
          }),
          mergeMap((user) => [setUser({ payload: user })]),
          catchError((error: unknown) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  mergeCustomTranslations$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setUser),
        filter(({ payload }) => !!payload),
        map(({ payload: user }) => {
          const keys = Object.keys(user!.organisation).filter((key) =>
            key.startsWith(`custom_translations_`),
          );
          keys?.forEach((key) => {
            const lang = key.split('_')?.pop();
            this.transloco.setTranslation(user!.organisation[key], lang, {
              merge: true,
            });
          });
        }),
      ),
    { dispatch: false },
  );

  fetchUserAndRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserAndRedirect),
      switchMap(({ route, delay }) =>
        this.userService.getUser().pipe(
          mergeMap((user) => {
            setTimeout(() => {
              this.router.navigate(route);
            }, delay ?? 0);
            return [setUser({ payload: user })];
          }),
          catchError((error: unknown) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  saveUserLocally$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveUserLocally),
        switchMap(({ user }) => {
          if (user) {
            localStorage.setItem('user', JSON.stringify(user));
          } else {
            localStorage.removeItem('user');
          }
          return EMPTY;
        }),
        catchError(() => EMPTY),
      ),
    { dispatch: false },
  );

  restoreFromStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(restoreUserLocally),
      switchMap(() => {
        try {
          const user = JSON.parse(localStorage.getItem('user')!) as User;
          return [setUser({ payload: user })];
        } catch {
          return [];
        }
      }),
      catchError(() => EMPTY),
    ),
  );

  onUnauthorized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(handleHttpError),
      switchMap(({ error }) => {
        if ((error as HttpErrorResponse).status === 401) {
          return [logout()];
        } else {
          return [];
        }
      }),
    ),
  );

  changeUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchUser),
      withLatestFrom(
        this.store.pipe(
          select(selectUser),
          map((user) => user?.url),
        ),
      ),
      switchMap(
        ([
          {
            payload: { data, successMessage, snackClass, formId },
          },
          url,
        ]) =>
          this.utilsService.patch<UserChangedData, User>(url, data).pipe(
            mergeMap((payload) => {
              const actions: Action[] = [setUser({ payload })];
              if (successMessage) {
                actions.push(
                  showSnackbarMessage({ message: successMessage, snackClass }),
                );
              }
              return actions;
            }),
            catchError((error: unknown) => [
              handleHttpError({ error, formId }),
            ]),
          ),
      ),
    ),
  );

  changeUserPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changePassword),
      switchMap(({ payload: { data, formId } }) =>
        this.userService.changePassword(data).pipe(
          mergeMap((res) => [showSnackbarMessage({ message: res.detail })]),
          catchError((error: unknown) => [handleHttpError({ error, formId })]),
        ),
      ),
    ),
  );

  setUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setUser),
      withLatestFrom(this.store.select(selectGlobalLanguage)),
      switchMap(([{ payload: user }, lang]) => {
        const actions: Action[] = [saveUserLocally({ user })];
        if (user?.settings?.language && user?.settings?.language !== lang) {
          actions.push(
            setGlobalLanguage({ payload: user?.settings?.language }),
          );
        }
        return actions;
      }),
    ),
  );

  patchUserLang$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setGlobalLanguage),
      withLatestFrom(this.store.select(selectUser)),
      switchMap(([{ payload: lang }, user]) => {
        if (user && !user?.terminal && user?.settings?.language !== lang) {
          return [
            patchUser({ payload: { data: { settings: { language: lang } } } }),
          ];
        }
        return [];
      }),
    ),
  );

  deleteAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAccount),
      withLatestFrom(this.store.pipe(select(selectUser))),
      switchMap(([, user]) =>
        this.utilsService.delete(user?.url).pipe(
          mergeMap(() => [
            showSnackbarMessage({ message: this.deleteSuccessMsg }),
            logoutCompletely(),
          ]),
          catchError((error: unknown) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  fetechAvailableReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAvailableReports),
      withLatestFrom(this.store.pipe(select(selectIsSimpleAuth))),
      filter(([, isSimpleAuth]) => !isSimpleAuth),
      switchMap(() =>
        this.userService.getReports().pipe(
          mergeMap((reports) => [setAvailableReports({ reports })]),
          catchError((error: unknown) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  downloadReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadReport),
      withLatestFrom(this.store.select(selectGlobalLanguage)),
      mergeMap(([{ report, response }, lang]) =>
        this.userService.downloadReport(lang, report.name, response).pipe(
          tap(() => this.store.dispatch(setReportDownloading({ report }))),
          retry(1),
          map((res) => {
            if (res == undefined)
              // eslint-disable-next-line sonarjs/no-throw-literal
              throw new HttpErrorResponse({
                error: 'Timeout',
                status: 400,
              }) as Error;
            return res;
          }),
          mergeMap((res: HttpResponse<Blob>) => {
            downloadFile(res);
            return [removeReportDownloading({ report })];
          }),
          catchError((error: unknown) => {
            const exception =
              ((error as HttpErrorResponse).error as string) ||
              (error as HttpErrorResponse).message ||
              error;
            if (exception instanceof Error) {
              Sentry.captureException(exception);
            } else {
              const newError = new Error(
                `Failed to load ${JSON.stringify(error)}`,
              );
              Sentry.captureException(newError);
            }
            return [
              handleHttpError({ error }),
              removeReportDownloading({ report }),
            ];
          }),
        ),
      ),
    ),
  );

  openReport$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewReport),
        withLatestFrom(this.store.select(selectGlobalLanguage)),
        switchMap(([{ report }, lang]) => {
          const url = `${API_CONFIG.orderTakingApi}/reports/render/?report=${report}&lang=${lang}`;
          this.router.navigate([`redirect`], {
            state: { newTab: true },
            queryParams: { next: encodeURIComponent(url) },
          });
          return EMPTY;
        }),
        catchError((error: unknown) => {
          const exception =
            ((error as HttpErrorResponse).error as string) ||
            (error as HttpErrorResponse).message ||
            error;
          if (exception instanceof Error) {
            Sentry.captureException(exception);
          } else {
            const newError = new Error(
              `Failed to load ${JSON.stringify(error)}`,
            );
            Sentry.captureException(newError);
          }
          return [handleHttpError({ error })];
        }),
      ),
    { dispatch: false },
  );

  connectWebSocket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(connectWebSocket),
      withLatestFrom(this.store.select(selectUser)),
      switchMap(([, user]) => {
        this.websockets.connect(`${user.organisation.identifier}_tickets`);
        return [];
      }),
    ),
  );

  disconnectWebSocket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(disconnectWebSocket),
        switchMap(() => {
          this.websockets.disconnect();
          return [];
        }),
      ),
    { dispatch: false },
  );

  reportWebSocketError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportWebSocketError),
      switchMap(() => [
        setGlobalError({
          error: this.transloco.translate('shared.errors.websockets-error'),
        }),
      ]),
    ),
  );

  private deleteSuccessMsg: string;

  constructor() {
    this.transloco
      .selectTranslate(`settings.delete-account.success`)
      .subscribe((msg: string) => (this.deleteSuccessMsg = msg));
  }
}
